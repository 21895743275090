<!-- 
Copyright 2021
  __  __                       _           _     _   _            _ _   _
 |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
 | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
 | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
 |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|

  All Rights Reserved.

 NOTICE:  All information contained herein is, and remains
 the property of Moonshot Health Incorporated and its suppliers,
 if any.  The intellectual and technical concepts contained
 herein are proprietary to Adobe Systems Incorporated
 and its suppliers and may be covered by U.S. and Foreign Patents,
 patents in process, and are protected by trade secret or copyright law.
 Dissemination of this information or reproduction of this material
 is strictly forbidden unless prior written permission is obtained
 from Moonshot Health Incorporated.
-->
<template>
  <v-navigation-drawer
    dark
    app
    v-model="drawer"
    :expand-on-hover="$vuetify.breakpoint.smOnly"
    :permanent="$vuetify.breakpoint.smAndUp"
    mini-variant-width="70"
    color="#181C32"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="@/assets/logo/logo_transparent_background.png"></v-img>
      </v-list-item-avatar>

      <v-list-item-title>Moonshot Health</v-list-item-title>
    </v-list-item>

    <v-list dense flat>
      <v-list-item-group color="primary">
        <v-list-item
          :to="{
            name: 'dashboard',
            params: { buildingId: this.$route.params.buildingId },
          }"
        >
          <v-list-item-icon>
            <v-icon class="pl-1" small>fa-layer-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("msh.aside.dashboard")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'floors',
            params: { buildingId: this.$route.params.buildingId },
          }"
        >
          <v-list-item-icon>
            <v-icon class="pl-1" small>far fa-building</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("msh.aside.appartments")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          :to="{
            name: 'listDevices',
            params: { buildingId: this.$route.params.buildingId },
          }"
        >
          <v-list-item-icon>
            <v-icon class="pl-1" small>fa-tablet</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t("msh.aside.devices") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="isMoonshotUser"
          :to="{
            name: 'buildingRecords',
            params: { buildingId: this.$route.params.buildingId },
          }"
        >
          <v-list-item-icon>
            <v-icon class="pl-1" small>fa-solid fa-list</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{
              $t("msh.aside.recordings")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'

.v-list-item-group .v-list-item--active .v-list-item__title
    color: white

.v-list-item-group .v-list-item--active .v-list-item__icon
    color: var(--v-primary-base)

.v-list-item-group .v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) .v-list-item__icon .theme--dark.v-icon
    color: #a2a3b7 !important

.v-list-item-group .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
    color: #a2a3b7 !important
</style>
<script>
import { bus } from "@/main.js";
import { SIDENAVIGATION_TOGGLE } from "@/events";
export default {
  name: "BusinessSideNavigation",
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    isMoonshotUser: function () {
      let userEmail = this.$store.getters.getEmail;
      if (userEmail) {
        return userEmail.endsWith("@moonshothealth.com");
      }
      return false;
    },
  },
  created: function () {
    bus.$on(SIDENAVIGATION_TOGGLE, () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>
