<!-- 
Copyright 2021
  __  __                       _           _     _   _            _ _   _
 |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
 | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
 | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
 |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|

  All Rights Reserved.

 NOTICE:  All information contained herein is, and remains
 the property of Moonshot Health Incorporated and its suppliers,
 if any.  The intellectual and technical concepts contained
 herein are proprietary to Adobe Systems Incorporated
 and its suppliers and may be covered by U.S. and Foreign Patents,
 patents in process, and are protected by trade secret or copyright law.
 Dissemination of this information or reproduction of this material
 is strictly forbidden unless prior written permission is obtained
 from Moonshot Health Incorporated.
-->
<template>
  <v-menu tile offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-none font-weight-regular"
        dark
        text
        v-bind="attrs"
        v-on="on"
      >
        {{ profile.name }}
        <v-icon right>mdi-account-circle-outline </v-icon>
      </v-btn>
    </template>
    <v-card outlined class="mx-auto" max-width="344">
      <v-card-title>{{ $t("msh.user.profile") }}</v-card-title>
      <v-card-subtitle
        ><strong>{{ profile.name }}</strong></v-card-subtitle
      >
      <v-card-text>
        <v-icon small> mdi-email </v-icon>
        <small>{{ profile.email }}</small>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed color="primary" to="/logout">{{
          $t("msh.user.signOut")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<style lang="sass" scoped>
.v-app-bar .v-btn
    letter-spacing: 0
</style>
<script>
import { mapState } from "vuex";
export default {
  name: "DropdownProfile",
  computed: {
    ...mapState({
      profile: (state) => state.profile.profile,
    }),
  },
};
</script>
