var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"dark":"","app":"","expand-on-hover":_vm.$vuetify.breakpoint.smOnly,"permanent":_vm.$vuetify.breakpoint.smAndUp,"mini-variant-width":"70","color":"#181C32"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"px-2"},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":require("@/assets/logo/logo_transparent_background.png")}})],1),_c('v-list-item-title',[_vm._v("Moonshot Health")])],1),_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"to":{
          name: 'dashboard',
          params: { buildingId: this.$route.params.buildingId },
        }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("fa-layer-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("msh.aside.dashboard")))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'floors',
          params: { buildingId: this.$route.params.buildingId },
        }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("far fa-building")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("msh.aside.appartments")))])],1)],1),_c('v-list-item',{attrs:{"to":{
          name: 'listDevices',
          params: { buildingId: this.$route.params.buildingId },
        }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("fa-tablet")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("msh.aside.devices")))])],1)],1),(_vm.isMoonshotUser)?_c('v-list-item',{attrs:{"to":{
          name: 'buildingRecords',
          params: { buildingId: this.$route.params.buildingId },
        }}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"pl-1",attrs:{"small":""}},[_vm._v("fa-solid fa-list")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("msh.aside.recordings")))])],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }