<!-- 
Copyright 2021
  __  __                       _           _     _   _            _ _   _
 |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
 | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
 | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
 |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|

  All Rights Reserved.

 NOTICE:  All information contained herein is, and remains
 the property of Moonshot Health Incorporated and its suppliers,
 if any.  The intellectual and technical concepts contained
 herein are proprietary to Adobe Systems Incorporated
 and its suppliers and may be covered by U.S. and Foreign Patents,
 patents in process, and are protected by trade secret or copyright law.
 Dissemination of this information or reproduction of this material
 is strictly forbidden unless prior written permission is obtained
 from Moonshot Health Incorporated.
-->
<template>
  <v-menu tile top offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="text-none font-weight-regular"
        text
        v-bind="attrs"
        v-on="on"
      >
        {{ activeLanguage.name }}
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        class=""
        link
        v-for="(item, i) in languages"
        v-bind:key="i"
        :disabled="isActiveLanguage(item.lang)"
        @click="selectedLanguage(item.lang)"
      >
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style lang="sass" scoped>
.v-footer .v-btn
    letter-spacing: 0
</style>
<script>
import i18nService from "@/services/i18n.service.js";

export default {
  name: "DropdownLanguage",
  data() {
    return {
      languages: i18nService.languages,
    };
  },
  methods: {
    selectedLanguage(lang) {
      i18nService.setActiveLanguage(lang);

      this.$emit(
        "language-changed",
        this.languages.find((val) => {
          return val.lang === lang;
        })
      );

      window.location.reload();
    },
    isActiveLanguage(current) {
      return i18nService.getActiveLanguage() === current;
    },
  },
  computed: {
    activeLanguage() {
      return i18nService.getLanguage(i18nService.getActiveLanguage());
    },
  },
};
</script>
