<!-- 
Copyright 2021
  __  __                       _           _     _   _            _ _   _
 |  \/  | ___   ___  _ __  ___| |__   ___ | |_  | | | | ___  __ _| | |_| |__
 | |\/| |/ _ \ / _ \| '_ \/ __| '_ \ / _ \| __| | |_| |/ _ \/ _` | | __| '_ \
 | |  | | (_) | (_) | | | \__ \ | | | (_) | |_  |  _  |  __/ (_| | | |_| | | |
 |_|  |_|\___/ \___/|_| |_|___/_| |_|\___/ \__| |_| |_|\___|\__,_|_|\__|_| |_|

  All Rights Reserved.

 NOTICE:  All information contained herein is, and remains
 the property of Moonshot Health Incorporated and its suppliers,
 if any.  The intellectual and technical concepts contained
 herein are proprietary to Adobe Systems Incorporated
 and its suppliers and may be covered by U.S. and Foreign Patents,
 patents in process, and are protected by trade secret or copyright law.
 Dissemination of this information or reproduction of this material
 is strictly forbidden unless prior written permission is obtained
 from Moonshot Health Incorporated.
-->
<template>
  <v-app>
    <BusinessSideNavigation v-bind:drawer="drawer"></BusinessSideNavigation>

    <v-app-bar flat color="primary" app>
      <v-app-bar-nav-icon
        v-if="!$vuetify.breakpoint.smAndUp"
        color="white"
        @click="openSideNavigation"
      >
      </v-app-bar-nav-icon>

      <DropdownBuilding></DropdownBuilding>

      <div class="spacer"></div>
      <DropdownProfile></DropdownProfile>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main style="margin-top: 0px">
      <v-sheet color="primary" class="d-flex" height="300">
        <template v-for="(breadcrumb, i) in breadcrumbs">
          <router-link
            v-if="breadcrumb.route"
            :key="i"
            :to="breadcrumb.route"
            class=""
          >
            <span class="text-white" :key="i" v-if="breadcrumb.route">
              {{ breadcrumb.title }}
            </span>
          </router-link>
          <h2 class="white--text pl-4" :key="i">
            {{ breadcrumb.title }}
          </h2>
        </template>
      </v-sheet>
      <!-- Provides the application the proper gutter -->
      <v-container style="margin-top: -200px" fluid>
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <v-spacer></v-spacer>
      <DropdownLanguage></DropdownLanguage>
    </v-footer>
  </v-app>
</template>
<style lang="sass">
.v-app-bar .v-btn
    letter-spacing: 0

.v-toolbar__extension
    align-items: flex-start !important
</style>
<script>
import { mapGetters } from "vuex";
import DropdownLanguage from "@/views/components/DropdownLanguage.vue";
import BusinessSideNavigation from "@/views/components/BusinessSideNavigation.vue";
import DropdownBuilding from "@/views/components/DropdownBuilding.vue";
import DropdownProfile from "@/views/components/DropdownProfile.vue";
import { SIDENAVIGATION_TOGGLE } from "@/events";
import { bus } from "@/main.js";

export default {
  components: {
    DropdownBuilding,
    DropdownLanguage,
    DropdownProfile,
    BusinessSideNavigation,
  },
  data() {
    return {
      drawer: true,
    };
  },
  methods: {
    openSideNavigation: function () {
      bus.$emit(SIDENAVIGATION_TOGGLE);
    },
  },
  computed: {
    ...mapGetters(["breadcrumbs"]),
  },
};
</script>
